import React from "react";
import classnames from "classnames/bind";
import * as footerStyles from "./footer.module.css";
import MenuItem from "../MenuItem";
import isNoItem from "@v4/utils/isNoItem";

const cx = classnames.bind(footerStyles);

const FooterSub = ({ subMenu }) => (
    <ul className={cx("subMenu")}>
        {subMenu.entries.map((entry, i) => {
            if (isNoItem(entry.title)) return null;
            if (entry.title.toLowerCase() === "[[cookie settings]]")
                return (
                    <li key={entry.id}>
                        <button id="ot-sdk-btn" className="ot-sdk-show-settings">
                            Cookie Settings
                        </button>
                    </li>
                );
            return (
                <li key={entry.id}>
                    <MenuItem item={entry} loc="footer" cx={cx} />
                </li>
            );
        })}
    </ul>
);

export default FooterSub;
