import CTA from "../components/cta/Cta";
// import MarketoForm from "../components/marketoForm/MarketoForm";
import MicroBanner from "../components/microBanner/MicroBanner";
import MicroImage from "@v4/gatsby-theme-talend/src/components/MicroImage/MicroImage";
import format from "@v4/utils/formatContentfulRichText";

// const formatContentfulRichText = () => format(MarketoForm, MicroBanner);
const formatContentfulRichText = () => format(null, MicroBanner, CTA, MicroImage);

export default formatContentfulRichText;
