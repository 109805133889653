import React from "react";
import Link from "@v4/gatsby-theme-talend/src/components/Link";

function MenuItem({ item, loc, newWindow, onClick, cx }) {
    // Set up Menu Item's content: Title, Description, and Icon
    const itemTitle = <span className={cx("menuItemTitle")}>{item.title}</span>;
    const itemDescription = item.description ? <span className={cx("description")}>{item.description}</span> : "";
    const icon = item.icon ? (
        <img width="32" height="32" alt="&bull;" className={cx("menuIcon")} src={item.icon[0]["original_secure_url"]} />
    ) : null;

    // Handle different item types (URL vs Page Ref vs neither)
    const to = item.url || (item.pageLink && item.pageLink.slug) || "#";

    return (
        <Link to={to} onClick={onClick} newWindow={item.newWindow} language={item.langCode}>
            {icon}
            {itemTitle}
            {itemDescription}
        </Link>
    );
}

export default MenuItem;
