import React from "react";
import { Helmet } from "react-helmet";
import { baseSchema, blogPostSchema, articleSchema, newsArticleSchema } from "./SchemaPartials";

const SchemaOrg = (props) => {
    // Schema type map
    const schemaTypes = {
        Base: baseSchema(props),
        BlogPost: blogPostSchema(props),
        Article: articleSchema(props),
        NewsArticle: newsArticleSchema(props),
    };

    // Figure out if the page, based on non-prefixed slug
    // Needs specific schema added to it
    const pageSchema = (slug) => {
        if (slug && slug.startsWith("/solutions/")) {
            return schemaTypes["Article"];
        }

        return null;
    };

    const schemaType = props.type ? schemaTypes[props.type] : pageSchema(props.pathname);

    const schema = [
        {
            "@context": "http://schema.org",
            "@graph": [...schemaTypes["Base"], ...(schemaType ? schemaType : [])],
        },
    ];

    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(schema)}</script>
        </Helmet>
    );
};

export default SchemaOrg;
