import React from "react";
import Link from "../Link";
import isNoItem from "@v4/utils/isNoItem";
import { graphql } from "gatsby";

export const fragment = graphql`
    fragment Cta on ContentfulCta {
        variant
        title
        url
        newWindow
        pageLink {
            ...PageLink
        }
    }
`;

const CTA = ({ cx, ...props }) => {
    // Return Null if it's a "No Item" so the CTA doesn't render anything
    if (isNoItem(props.title)) return null;

    // The "to" property can either be a raw URL or a page reference, or neither (#noanchor)
    const to = props.url || (props.pageLink && props.pageLink.slug) || "#noanchor";

    // The variant of the button, with whitespace removed
    const variant = props.variant ? props.variant.replace(/\s+/g, "") : "Primary";

    // Custom classes
    const customClass = props.customClass ?? "";

    // Setting up classes
    const btnClasses = [`btn`, `btn${variant}`, customClass, cx(`btn`, `btn${variant}`, customClass)];

    return (
        <Link to={to} className={btnClasses.join(" ")} newWindow={props.newWindow} language={props.language}>
            {props.title}
        </Link>
    );
};

export default CTA;
