import React from "react";
import { graphql } from "gatsby";
import HeaderMenuItem from "./HeaderMenuItem";
import camelCase from "lodash.camelcase";

export const fragment = graphql`
    fragment GroupFields on ContentfulMenuGroup {
        name
        groupTitle
        variant
        __typename
        id
    }
`;

const MenuGroup = ({ group, loc, cx }) => {
    // setup vars
    const subItems = group.subItems ? group.subItems : null;
    const groupLevel = group.level + 1;
    const variant = group.variant ? camelCase(String(group.variant)) : null;

    const groupClass = cx(
        {
            hasChildren: subItems,
        },
        variant,
        `groupMenuLevel${groupLevel}`
    );

    return (
        <li className={groupClass}>
            {group.groupTitle && <span className={cx("groupTitle")}>{group.groupTitle}</span>}
            {subItems && (
                <ul className={cx("subMenu", "menuGroup", "menuLevel" + groupLevel)}>
                    {subItems.map((entry, i) => {
                        entry.level = groupLevel + 1;
                        if (entry.__typename === "ContentfulMenuItem") {
                            return <HeaderMenuItem key={entry.id} item={entry} loc={loc} cx={cx} />;
                        }
                        if (entry.__typename === "ContentfulMenuGroup") {
                            return <MenuGroup key={entry.id} group={entry} cx={cx} />;
                        }
                        return null;
                    })}
                </ul>
            )}
        </li>
    );
};

export default MenuGroup;
