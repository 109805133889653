import React from "react";
import formatClassName from "./formatClassName";
import classnames from "classnames";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";
const tailwindConfig = JSON.parse(process.env["GATSBY_CONFIG_TAILWIND_CONFIG"] ?? "{}");

const stylingOptions = (options, cx = classnames) => {
    // options validation
    if (!options) return {};

    // Setup variables for use in return
    const styleOpts = {
        fullClass: "", // String of only the class names derived from Styling Options
        options: {}, // Contains all of the styling options
        classes: [], // The associated CSS classes derived from the options
        hexColors: [], // Any hexadecimal colors
    };

    // Iterate over entries for use in class names
    Object.entries(options).forEach(([key, value]) => {
        if (key.toLowerCase().indexOf("hex") >= 0 && value) {
            // If the styling option has to do with Hex colors, add to both the root and the classList
            styleOpts.hexColors[key] = `#${value}`;
        } else if (key === "bgImage" && options.bgImage) {
            // Set up properties for background image:
            styleOpts.bgImageStyles = { ...options.bgImgStyles };
            if (options.bgImage[0]) {
                styleOpts.bgImage = options.bgImage[0];
                styleOpts.bgImageStyles.position = "absolute";
                styleOpts.bgImageStyles.zIndex = "1";
                // Fallback to "center" for backgroundPosition
                styleOpts.bgImageStyles.backgroundPosition = options.bgPosition
                    ? options.bgPosition.toLowerCase()
                    : "center";
                // Fallback to "cover" for backgroundSize
                styleOpts.bgImageStyles.backgroundSize = options.bgSize ? options.bgSize.toLowerCase() : "cover";
                styleOpts.bgImageStyles.backgroundRepeat = options.bgRepeat
                    ? options.bgRepeat.toLowerCase()
                    : "no-repeat";
            }
        } else if ((key === "borderTop" || key === "borderBottom") && value) {
            // Border classes are based on the presence of their keys, unlike other classes
            styleOpts.fullClass = `${styleOpts.fullClass} ${key}`;
            styleOpts.options[key] = key;
        } else if (key === "containerBounds" && options?.containerBounds?.length) {
            // Container bounds modifier -- ensures background images do not flow outside of the site's max width
            options.bgElStyles = options.containerBounds?.length
                ? {
                      maxWidth: tailwindConfig?.theme?.screens?.lg ?? "none",
                      margin: "auto",
                      left: "0",
                      right: "0",
                      ...options.bgElStyles,
                  }
                : { ...options.bgElStyles };
        } else if (value && !key.toLowerCase().includes("image")) {
            // If there's a value present, and the value is unrelated to "image"
            // Reduce 'alignment' string for class names
            if (key.includes("Alignment")) key = key.replace("Alignment", "Align");

            // Create new class name (ie: 'spacingTopL'), remove spaces, camelize
            const className = formatClassName(key, value);

            // Add to the full "className" string
            styleOpts.fullClass = styleOpts.fullClass + " " + className;

            // Amend to the key/value array of option keys and values
            styleOpts.classes[key] = className;

            // Setting up options listing for producing unique set later
            styleOpts.options[key] = className;

            // Adding true value for use w/the cx() classnames utility
            styleOpts[`${key}${value}`] = true;
        }
    });

    // Produce a collection of unique classnames with CSS Modules taken into account
    // Array for all classes currently attributed from Options
    let classes = Object.values(styleOpts.options);

    // Array of both global- and module-scoped classes
    classes = [...classes, ...cx(classes).split(" ")];

    // New "Set" of uniquely named CSS classes: a mix of global and scoped class names
    styleOpts.classes = [...new Set(classes)];

    // A string of unique classes
    styleOpts.classString = styleOpts.classes.join(" ");

    // Handle production of border elements and styles
    styleOpts.borderTopStyle = styleOpts.hexColors.borderTopHex
        ? { backgroundColor: styleOpts.hexColors.borderTopHex }
        : null;
    styleOpts.borderTopEl = styleOpts.options.borderTop ? (
        <hr className={`${cx("borderTop")} borderTop`} style={styleOpts.borderTopStyle} />
    ) : null;

    styleOpts.borderBottomStyle = styleOpts.hexColors.borderBottomHex
        ? { backgroundColor: styleOpts.hexColors.borderBottomHex }
        : null;
    styleOpts.borderBottomEl = styleOpts.options.borderBottom ? (
        <hr className={`${cx("borderBottom")} borderBottom`} style={styleOpts.borderBottomStyle} />
    ) : null;

    // Pass the inline style necessary for setting bgHex.
    styleOpts.bgHex = styleOpts.hexColors.bgHex ? { background: styleOpts.hexColors.bgHex } : {};

    // Handle BG images with the Image component, using the object-fit and object-position styles,
    // as well as a specific 'backgroundImage' class name. "bgElStyles" can be passed from parent components.
    styleOpts.bgImageEl = styleOpts.bgImage && (
        <Image
            image={styleOpts.bgImage}
            className={`backgroundImage`}
            style={{ ...options.bgElStyles }}
            imgStyle={{
                objectFit: styleOpts.bgImageStyles.backgroundSize,
                objectPosition: styleOpts.bgImageStyles.backgroundPosition,
                ...options.bgImgStyles,
            }}
        />
    );

    return styleOpts;
};

export default stylingOptions;
