// extracted by mini-css-extract-plugin
export var button = "header-module--button--4cc78";
export var buttonSecondary = "header-module--buttonSecondary--485e3";
export var closer = "header-module--closer--795bd";
export var description = "header-module--description--a4e7b";
export var groupTitle = "header-module--groupTitle--7a3e1";
export var hasChildren = "header-module--hasChildren--838ab";
export var hasSideBySideChildren = "header-module--hasSideBySideChildren--becda";
export var headerLogo = "header-module--headerLogo--4c5ad";
export var heading = "header-module--heading--6f1ca";
export var indented = "header-module--indented--b6355";
export var indentedLast = "header-module--indentedLast--a5501";
export var innerWrapper = "header-module--innerWrapper--99057";
export var isOpen = "header-module--isOpen--7591b";
export var isSticky = "header-module--isSticky--1ec0e";
export var itemMenuLevel1 = "header-module--itemMenuLevel1--c34ce";
export var itemMenuLevel2 = "header-module--itemMenuLevel2--247b2";
export var itemMenuLevel3 = "header-module--itemMenuLevel3--7900f";
export var itemMenuLevel4 = "header-module--itemMenuLevel4--34780";
export var large = "header-module--large--81e05";
export var largeCloser = "header-module--largeCloser--4dfe4";
export var mainHeader = "header-module--mainHeader--f6077";
export var mainNav = "header-module--mainNav--e5b72";
export var mainNavList = "header-module--mainNavList--37cd5";
export var menuIcon = "header-module--menuIcon--e4f74";
export var menuItem = "header-module--menuItem--4d2a2";
export var menuItemTitle = "header-module--menuItemTitle--fa862";
export var menuLevel2 = "header-module--menuLevel2--5cacf";
export var navButtonLevel1 = "header-module--navButtonLevel1--ef35b";
export var navGlobeLevel1 = "header-module--navGlobeLevel1--32f38";
export var navTrigger = "header-module--navTrigger--10494";
export var reduceOverflow = "header-module--reduceOverflow--ae5bb";
export var sideBySide = "header-module--sideBySide--31a1f";
export var skipLink = "header-module--skipLink--306ee";
export var subMenu = "header-module--subMenu--e865a";
export var subMenuArrow = "header-module--subMenuArrow--34266";