const { normalizePath } = require("@v4/utils/normalizePathOrUrl");
const { createI18nHelper } = require("@v4/utils/i18nHelper");
const i18n = createI18nHelper();

// Array to house entry type and pathing info
const pageTypePrefixes = JSON.parse(process.env.GATSBY_CONFIG_PAGE_TYPE_PREFIXES);

/**
 * Returns a path, with slug, for the corresponding entry type.
 *
 * @param slug
 *   The slug of the entry, without prefix.
 * @param pageType
 *   Contentful entry type - ie: "ContentfulBlog"
 */
const getPrefixedPathByPageType = function(slug, pageType) {
    if (!pageType) return slug;

    // Using verbose ternary for gatsby-node support
    const prefix = pageTypePrefixes[pageType] ? pageTypePrefixes[pageType] : "";

    return normalizePath(`/${prefix}/${slug}/`);
};

/**
 * Returns a Contentful entry type value (ie: "ContentfulBlog") based on the full path.
 *
 * @param path
 *   Full path to the entry, including any prefixes (ie: "/blog/").
 */
const getPageTypeByPath = function(path) {
    return Object.keys(pageTypePrefixes).find((pageType) => path.startsWith(pageTypePrefixes[pageType]));
};

/**
 * Returns a path in combination with the passed locale, takes ja/jp into account.
 *
 * @param path
 *   Full path to the entry, including any prefixes (ie: "/blog/").
 * @param locale
 *   ISO 639 language code (called locale by contentful).
 */
const getLangPrefixedPath = function(path, locale) {
    const prefix = i18n.get("pathPrefix").from("langCode", locale);

    // Remove repeating slashes.
    return normalizePath(`/${prefix}/${path}/`);
};

/**
 * Returns a fully prefixed path, taking both page type and locale into account.
 *
 * @param slug
 *   The slug of the entry, without prefix.
 * @param pageType
 *   Contentful entry type - ie: "ContentfulBlog"
 * @param locale
 *   ISO 639 language code (called locale by contentful).
 */
const getLangPrefixedPathByPageType = function(slug, pageType, locale) {
    return getLangPrefixedPath(getPrefixedPathByPageType(slug, pageType), locale);
};

module.exports = {
    getPrefixedPathByPageType,
    getPageTypeByPath,
    getLangPrefixedPath,
    getLangPrefixedPathByPageType,
};
