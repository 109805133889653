/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import { useI18n } from "@v4/utils/i18nContext";
import { normalizeUrl } from "@v4/utils/normalizePathOrUrl";
import SchemaOrg from "./SchemaOrg";

export const fragment = graphql`
    fragment meta on ContentfulMetaFields {
        metaTitle
        metaDescription
        metaRobot
        googleBot
        bingBot
        canonicalUrl {
            slug
        }
        canonicalUrlOverride
        metaImage {
            original_secure_url
            height
            width
        }
    }
`;
const SEO = (props) => {
    const {
        description,
        meta,
        metaImage,
        title,
        robots,
        canonicalUrl,
        canonicalUrlOverride,
        googleBot,
        bingBot,
        modifiedTime,
    } = props;

    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteUrl
                        siteName
                        robots
                        googleBot
                        bingBot
                        metaimagePath
                        metaimageHeight
                        metaimageWidth
                    }
                }
            }
        `
    );

    // Grab the i18n helper and pull the current page's information from the context.
    const { i18n, translatedPaths } = useI18n();

    // Title variable
    const pageTitle = title || site.siteMetadata.title;

    // MetaDescription variable , check if the value is passed to the SEO function
    // If there is no value passed, use the default from gatsby-config.js
    const metaDescription = description || site.siteMetadata.description;

    // Meta robots variables, check if the values is null
    // If there is null value passed, use the default values from gatsby-config.js
    let metaRobots = robots ? robots.join(", ") : site.siteMetadata.robots.join(", ");
    let metaGoogleBot = googleBot ? googleBot.join(", ") : site.siteMetadata.googleBot.join(", ");
    let metaBingBot = bingBot ? bingBot.join(", ") : site.siteMetadata.bingBot.join(", ");

    // Meta Image
    const mImage =
        metaImage && metaImage[0]
            ? `${metaImage[0].original_secure_url}`
            : `${site.siteMetadata.siteUrl}/${site.siteMetadata.metaimagePath}`;

    const mImageSize = {
        height: metaImage?.[0]?.height ? metaImage && metaImage[0].height : site.siteMetadata.metaimageHeight,
        width: metaImage?.[0]?.width ? metaImage && metaImage[0].width : site.siteMetadata.metaimageWidth,
    };

    // Page URL
    const pageUrl = normalizeUrl(`${site.siteMetadata.siteUrl}/${translatedPaths[i18n.curr.langCode]}`);

    // Canonical link tag
    const canonical = canonicalUrlOverride
        ? canonicalUrlOverride
        : canonicalUrl
        ? normalizeUrl(`${site.siteMetadata.siteUrl}/${canonicalUrl.slug}`)
        : pageUrl;

    // HrefLang
    // Produce hrefLang tags based on present languages and pull the related paths from the context
    const hrefLangTags = Object.keys(translatedPaths).map((langCode) => {
        return {
            rel: "alternate",
            href: normalizeUrl(`${site.siteMetadata.siteUrl}/${translatedPaths[langCode]}`),
            hrefLang: langCode,
        };
    });

    // Add x-default
    if (Object.keys(translatedPaths).includes("en")) {
        hrefLangTags.push({
            rel: "alternate",
            href: normalizeUrl(`${site.siteMetadata.siteUrl}/${translatedPaths["en"]}`),
            hrefLang: "x-default",
        });
    }

    return (
        <>
            <Helmet
                htmlAttributes={{ lang: i18n.curr.langCode }}
                title={pageTitle}
                titleTemplate={`%s | ${site.siteMetadata.title}`}
                link={[
                    {
                        rel: "canonical",
                        href: canonical,
                    },
                    ...hrefLangTags,
                ]}
                meta={[
                    {
                        name: `description`,
                        content: metaDescription,
                    },
                    {
                        name: `robots`,
                        content: metaRobots,
                    },
                    {
                        name: `googlebot`,
                        content: metaGoogleBot,
                    },
                    {
                        name: `bingbot`,
                        content: metaBingBot,
                    },
                    {
                        property: `og:locale`,
                        content: i18n.curr.locale.replace("-", "_"),
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        property: `og:title`,
                        content: pageTitle,
                    },
                    {
                        property: `og:description`,
                        content: metaDescription,
                    },
                    {
                        property: `og:url`,
                        content: pageUrl,
                    },
                    {
                        property: `og:site_name`,
                        content: site.siteMetadata.siteName,
                    },
                    {
                        name: `article:modified_time`,
                        content: modifiedTime,
                    },
                    {
                        name: `twitter:creator`,
                        content: site.siteMetadata.author,
                    },
                    {
                        name: `twitter:title`,
                        content: pageTitle,
                    },
                    {
                        name: `twitter:description`,
                        content: metaDescription,
                    },
                ]
                    .concat(
                        mImage
                            ? [
                                  {
                                      property: "og:image",
                                      content: mImage,
                                  },
                                  {
                                      property: "og:image:width",
                                      content: mImageSize.width,
                                  },
                                  {
                                      property: "og:image:height",
                                      content: mImageSize.height,
                                  },
                                  {
                                      name: "twitter:card",
                                      content:
                                          title === "Qlik | Talend: Looking Forward | Blog"
                                              ? "summary_large_image"
                                              : "summary",
                                  },
                              ]
                            : [
                                  {
                                      name: "twitter:card",
                                      content: "summary",
                                  },
                              ]
                    )
                    .concat(meta)}
            />
            <SchemaOrg
                {...props}
                canonicalUrl={canonical}
                siteUrl={site.siteMetadata.siteUrl}
                siteDescription={site.siteMetadata.description}
                siteLogo={`${site.siteMetadata.siteUrl}/${site.siteMetadata.metaimagePath}`}
                siteTitle={site.siteMetadata.siteName}
                companyName={site.siteMetadata.title}
                currentLanguage={i18n.curr.langCode}
                currentLocale={i18n.curr.locale.replace("-", "_")}
                metaImage={mImage}
                metaImageSize={mImageSize}
            />
        </>
    );
};
SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};
SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
};
export default SEO;
