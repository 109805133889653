import React, { useEffect, useState } from "react";
import Menu from "./Menu.js";
import Link from "@v4/gatsby-theme-talend/src/components/Link";

const Header = ({ headerMenu, LogoSVG, cx }) => {
    // React Hooks for sticky header and device-nav toggle
    const [scrolled, setScrolled] = useState(false);
    const [toggled, setToggle] = useState(false);

    // Setup classes
    const headerClasses = cx({
        siteHeader: true,
        mainHeader: true,
        isSticky: scrolled,
        notSticky: !scrolled,
        isOpen: toggled,
    });

    // Event listener to execute the handleScroll function
    useEffect(() => {
        // Changes state of `scrolled` when window offset is > 0 (page is scrolled)
        const handleScroll = () => {
            const offset = window.pageYOffset;
            if (offset > 0) setScrolled(true);
            else setScrolled(false);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    });

    return (
        <>
            <header className={headerClasses} role="navigation">
                <div className={cx("innerWrapper")}>
                    <div className={cx("headerLogo")} id="logo">
                        <Link to="/">
                            <LogoSVG />
                        </Link>
                    </div>
                    <label
                        id="navTrigger"
                        className={cx("navTrigger")}
                        tabIndex="0"
                        role="button"
                        aria-pressed="false"
                        aria-expanded={toggled}
                        onClick={() => setToggle(!toggled)}
                    >
                        <span>Main Navigation</span>
                    </label>
                    <Menu loc="header" data={headerMenu} cx={cx} />
                </div>
            </header>
            <a href="#main" className={cx("skipLink")}>
                Skip to main content
            </a>
        </>
    );
};

export default Header;
