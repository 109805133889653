import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";
import { useI18n } from "@v4/utils/i18nContext";
const { minimatch } = require("minimatch");

export const useGlobalNotificationBars = () => {
    const { pathname: currentPath } = useLocation();
    const { i18n } = useI18n();

    // Grab all notification bars.
    let notificationBars = useStaticQuery(graphql`
        query NotificationBarQuery {
            allContentfulNotificationBar {
                nodes {
                    node_locale
                    paths {
                        paths
                    }
                    ...NotificationBar
                }
            }
        }
    `);

    // Filter out bars for other languages.
    notificationBars = notificationBars?.allContentfulNotificationBar?.nodes?.filter(
        (node) => node.node_locale === i18n.curr.langCode
    );

    // For each notification bar, check if the paths field is set and matches the current path. If so return that
    // notification bar.
    let notificationBar = null;
    notificationBars.forEach((nbar) => {
        const paths = nbar?.paths?.paths?.split("\n");

        // eslint-disable-next-line no-unused-expressions
        paths?.forEach((path) => {
            if (minimatch(currentPath, path)) {
                notificationBar = { props: nbar };
            }
        });
    });

    return notificationBar;
};
