import React, { useRef, useEffect, useCallback } from "react";
import { graphql } from "gatsby";
import { useMediaQuery } from "react-responsive";
import isDesktopQuery from "@v4/utils/mediaQueries";
import MenuGroup from "./MenuGroup";
import MenuItem from "../MenuItem";
import camelCase from "lodash.camelcase";
import isNoItem from "@v4/utils/isNoItem";
import { useI18n } from "@v4/utils/i18nContext";
import { normalizePath } from "@v4/utils/normalizePathOrUrl";
import { getElementDistanceFromScreenEdge } from "@v4/utils/getElementDistanceFromScreenEdge";

export const fragment = graphql`
    fragment ItemFields on ContentfulMenuItem {
        title
        description
        variant
        icon {
            original_secure_url
        }
        __typename
        url
        pageLink {
            ...PageLink
        }
        id
        newWindow
    }
`;

const HeaderMenuItem = ({ item, loc, newWindow, cx }) => {
    const { i18n, translatedPaths } = useI18n();
    // References and Hook for use in toggle and accordions
    const subMenuRef = React.createRef();
    const [isOpen, setOpen] = React.useState(false);
    const hasMenuCalc = useRef(false);

    // Vars for validation and fallbacks
    let subItems = item.subItems ? item.subItems : null;
    const itemLevel = item.level;
    const variant = item.variant ? camelCase(String(item.variant)) : null;

    // Establish classes
    const itemClass = cx(
        {
            menuItem: true,
            hasChildren: subItems,
            isOpen: isOpen,
            navLinkLevel1: itemLevel === 1 && !["globe", "button"].includes(variant),
            navButtonLevel1: itemLevel === 1 && ["button", "buttonSecondary"].includes(variant),
            navGlobeLevel1: itemLevel === 1 && variant === "globe",
        },
        "itemMenuLevel" + itemLevel,
        variant
    );

    // React Hook for modifying inline styles
    const [inlineStyles, setStyles] = React.useState({ height: null });

    // Remove explicit height of the menu item via hook
    const handleMediaQueryChange = () => {
        if (subItems && isDesktop && inlineStyles.height) {
            setStyles({ height: null });
            setOpen(!isOpen);
        }
    };

    // Media query w/handler
    const isDesktop = useMediaQuery(isDesktopQuery, undefined, handleMediaQueryChange);

    // Added to the accordion height, non-dynamic since accordion height would be calculated
    const heightCompensation = 46;

    // Mobile Accordion function
    const handleAccordion = (e) => {
        if (isDesktop) {
            // When on desktop, ensure there's no explicit inline height declarations for menu items.
            setStyles({ height: null });
        } else {
            // Open: Set the menu item's height to that of its inner-content (clientHeight)
            e.preventDefault();

            // Change presence of `isOpen` class via Hook
            setOpen(!isOpen);

            // Change set or unset inline styles for height
            if (inlineStyles.height === null) {
                setStyles({ height: subMenuRef.current.clientHeight + heightCompensation });
            } else {
                // Close: Unset the inline style
                setStyles({ height: null });
            }
        }
    };

    // Checks if the menu is off-screen and adjusts accordingly.
    const checkMenuForVisibility = useCallback(() => {
        if (isDesktop) {
            let elementDistanceFromScreenEdge = getElementDistanceFromScreenEdge(subMenuRef?.current, 10);
            if (elementDistanceFromScreenEdge?.left < 0) {
                subMenuRef.current.style.left = `0px`;
                elementDistanceFromScreenEdge = getElementDistanceFromScreenEdge(subMenuRef.current, 10);
                subMenuRef.current.style.left = `${-elementDistanceFromScreenEdge.left}px`;
            }
            if (elementDistanceFromScreenEdge?.right < 0) {
                subMenuRef.current.style.right = `${-elementDistanceFromScreenEdge.right}px`;
            }

            // Reset the flag so that the menu is only checked once per window resize event
            hasMenuCalc.current = true;
        }
    }, [isDesktop, subMenuRef]);

    useEffect(() => {
        // Reset flag on window resize
        window.addEventListener("resize", () => (hasMenuCalc.current = false));

        // Check the menu for visibility on mount
        if (hasMenuCalc.current === false && subItems) checkMenuForVisibility();

        return () => {};
    }, [checkMenuForVisibility, subItems]);

    // Alter the menu data for the language picker.
    if (variant === "globe") {
        // Globe should point to current path.
        item.url = translatedPaths[i18n.curr.langCode] + "#";

        subItems = subItems.filter((el) => {
            // Get the langCode for the current link.
            el.langCode = i18n.get("langCode").from("langName", el.title);

            // Get the path prefix for the current link's language.
            const elPathPrefix = i18n.get("pathPrefix").from("langName", el.title);

            // Link to the translated path if it exists, otherwise to the homepage for the language of the current link.
            el.url = translatedPaths[el.langCode] ?? normalizePath(elPathPrefix);

            // Don't show the current language.
            return elPathPrefix !== i18n.curr.pathPrefix;
        });
    }

    return (
        <li
            // Only check the menu for visibility when the calculation hasn't been done, or is invalidated by resize
            onMouseOver={() => {
                hasMenuCalc.current === false && subItems && checkMenuForVisibility();
            }}
            className={itemClass}
            style={inlineStyles}
        >
            <MenuItem item={item} loc={loc} onClick={subItems && handleAccordion} cx={cx} />
            {subItems && (
                <ul className={cx(`subMenu`, `menuLevel${itemLevel + 1}`)} ref={subMenuRef}>
                    {subItems.map((entry, i) => {
                        entry.level = itemLevel + 1;
                        if (isNoItem(entry.title)) return null;
                        if (entry.__typename === "ContentfulMenuItem") {
                            return <HeaderMenuItem key={entry.id} item={entry} loc={loc} cx={cx} />;
                        }
                        if (entry.__typename === "ContentfulMenuGroup") {
                            return <MenuGroup key={entry.id} group={entry} loc={loc} cx={cx} />;
                        }
                        return null;
                    })}
                </ul>
            )}
        </li>
    );
};

export default HeaderMenuItem;
