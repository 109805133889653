import React from "react";
import BaseHeader from "@v4/gatsby-theme-talend/src/components/header/Header";
import LogoSVG from "../../assets/svg/logo.inline.svg";
import * as headerStyles from "./header.module.css";
import classNames from "classnames/bind";
const cx = classNames.bind(headerStyles);


//  We need to pass the cx object (bound classes) all the wau down to the Header components and every other component
//  referenced from within the Header that also depends on classes in the header.module.css

const Header = (props) => <BaseHeader cx={cx} {...props} LogoSVG={LogoSVG} />;

export default Header;
