import { getLangPrefixedPath } from "@v4/utils/pathPrefix";

export const baseSchema = (props) => {
    const {
        siteUrl,
        canonicalUrl,
        siteTitle,
        siteDescription,
        description,
        title,
        currentLanguage,
        modifiedTime,
        createdTime,
    } = props;
    return [
        ...organizationSchema(props),
        {
            "@type": "WebSite",
            "@id": siteUrl + getLangPrefixedPath("/", currentLanguage) + "#website",
            url: siteUrl + getLangPrefixedPath("/", currentLanguage),
            name: siteTitle,
            description: siteDescription,
            inLanguage: currentLanguage,
            publisher: {
                "@id": siteUrl + "/#organization",
            },
        },
        {
            "@type": "WebPage",
            "@id": canonicalUrl + "#webpage",
            url: canonicalUrl,
            name: title,
            isPartOf: {
                "@id": siteUrl + getLangPrefixedPath("/", currentLanguage) + "#website",
            },
            datePublished: createdTime,
            dateModified: modifiedTime,
            description: description,
            inLanguage: currentLanguage,
            potentialAction: [
                {
                    "@type": "ReadAction",
                    target: [canonicalUrl],
                },
            ],
        },
    ];
};

export const blogPostSchema = (props) => {
    const {
        siteUrl,
        canonicalUrl,
        description,
        title,
        currentLanguage,
        modifiedTime,
        createdTime,
        metaImage,
        metaImageSize,
        siteLogo,
        companyName,
    } = props;
    return [
        {
            "@type": "BlogPosting",
            url: canonicalUrl,
            name: title,
            headline: title && title.replace(" | Talend", ""),
            image: {
                "@type": "ImageObject",
                url: metaImage,
                height: metaImageSize.height,
                width: metaImageSize.width,
            },
            description,
            author: {
                "@type": "Organization",
                url: siteUrl,
                name: companyName,
            },
            publisher: {
                "@type": "Organization",
                url: siteUrl,
                logo: {
                    "@type": "ImageObject",
                    url: siteLogo,
                    width: "512",
                    height: "512",
                },
                name: companyName,
            },
            isPartOf: {
                "@id": canonicalUrl + "#webpage",
            },
            mainEntityOfPage: canonicalUrl + "#webpage",
            dateCreated: createdTime,
            datePublished: createdTime,
            dateModified: modifiedTime,
            inLanguage: currentLanguage,
        },
    ];
};

const organizationSchema = (props) => {
    const { siteUrl, siteLogo, companyName } = props;
    return [
        {
            "@type": "Organization",
            url: siteUrl,
            logo: {
                "@type": "ImageObject",
                url: siteLogo,
                width: "512",
                height: "512",
            },
            name: companyName,
        },
    ];
};

export const articleSchema = (props) => {
    const {
        canonicalUrl,
        siteUrl,
        createdTime,
        modifiedTime,
        currentLanguage,
        title,
        description,
        metaImage,
        metaImageSize,
        siteLogo,
        companyName,
    } = props;
    return [
        {
            "@type": "Article",
            "@id": canonicalUrl + "#article",
            url: canonicalUrl,
            name: title,
            headline: title && title.replace(" | Talend", ""),
            image: {
                "@type": "ImageObject",
                url: metaImage,
                height: metaImageSize.height,
                width: metaImageSize.width,
            },
            description,
            isPartOf: {
                "@id": canonicalUrl + "#webpage",
            },
            author: {
                "@type": "Organization",
                url: siteUrl,
                name: companyName,
            },
            publisher: {
                "@type": "Organization",
                url: siteUrl,
                logo: {
                    "@type": "ImageObject",
                    url: siteLogo,
                    width: "512",
                    height: "512",
                },
                name: companyName,
            },
            dateCreated: createdTime,
            datePublished: createdTime,
            dateModified: modifiedTime,
            inLanguage: currentLanguage,
            mainEntityOfPage: canonicalUrl + "#webpage",
        },
    ];
};

export const newsArticleSchema = (props) => {
    const {
        canonicalUrl,
        siteUrl,
        createdTime,
        modifiedTime,
        currentLanguage,
        title,
        description,
        metaImage,
        metaImageSize,
        siteLogo,
        companyName,
    } = props;
    return [
        {
            "@type": "NewsArticle",
            url: canonicalUrl,
            name: title,
            headline: title && title.replace(" | Talend", ""),
            image: {
                "@type": "ImageObject",
                url: metaImage,
                height: metaImageSize.height,
                width: metaImageSize.width,
            },
            description,
            isPartOf: {
                "@id": canonicalUrl + "#webpage",
            },
            author: {
                "@type": "Organization",
                url: siteUrl,
                name: companyName,
            },
            publisher: {
                "@type": "Organization",
                url: siteUrl,
                logo: {
                    "@type": "ImageObject",
                    url: siteLogo,
                    width: "512",
                    height: "512",
                },
                name: companyName,
            },
            dateCreated: createdTime,
            datePublished: createdTime,
            dateModified: modifiedTime,
            inLanguage: currentLanguage,
            mainEntityOfPage: canonicalUrl + "#webpage",
        },
    ];
};

export const productSchema = (props) => {
    const { title, description, metaImage, metaImageSize } = props;
    return [
        {
            "@type": "Product",
            name: title,
            image: {
                "@type": "ImageObject",
                url: metaImage,
                height: metaImageSize.height,
                width: metaImageSize.width,
            },
            description: description,
            brand: {
                "@type": "Brand",
                name: "Talend",
            },
        },
    ];
};
