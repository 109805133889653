import React from "react";
import { graphql } from "gatsby";

export const fragment = graphql`
    fragment JumpDestination on ContentfulJumpDestination {
        id
        jumpId
    }
`;

const JumpDestination = (props) => {
    return <span className="jumpDestination" id={`${props.jumpId}`} style={{ visibility: "hidden" }} />;
};

export default JumpDestination;
