import React, { useRef } from "react";
import classNames from "classnames/bind";
import NotificationBar from "../modules/notificationBar/NotificationBar";
import stylingOptions from "@v4/utils/stylingOptions";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import TempPageProvider from "@v4/gatsby-theme-talend/src/components/TempPageProvider";
import { useGlobalNotificationBars } from "@v4/gatsby-theme-talend/src/components/modules/notificationBar/useGlobalNotificationBars";
import * as layoutStyles from "./layout.module.css";
import { graphql, useStaticQuery } from "gatsby";
const cx = classNames.bind(layoutStyles);

const Layout = ({ children, headerMenu, footerMenu, hasMinimalNav, notificationBar, pageStyleProps }) => {
    const pageStyleOpts = stylingOptions(pageStyleProps, cx) ?? {};

    // Adding wrapper ref for Notification Bar
    const pageWrapperRef = useRef();

    const globalNotificationBar = useGlobalNotificationBars();

    const notificationBarData = globalNotificationBar ?? notificationBar ?? null;

    // Begin temp query for page list
    const { allSitePage } = useStaticQuery(
        graphql`
            query {
                allSitePage {
                    nodes {
                        path
                    }
                }
            }
        `
    );

    const pageList = allSitePage?.nodes?.reduce((acc, curr) => [...acc, curr.path], []);
    // End temp query for page list

    return (
        <TempPageProvider data={pageList}>
            <div id="pageWrapper" ref={pageWrapperRef}>
                <div className={cx("topRegion")}>
                    {notificationBarData && (
                        <NotificationBar {...notificationBarData.props} pageWrapperRef={pageWrapperRef} />
                    )}
                    <Header headerMenu={headerMenu.mainNav} ctaMenu={headerMenu.mainNavCta} />
                </div>
                <div className="wrapper pageInterior">
                    <main id="main" className={cx(`${pageStyleOpts && pageStyleOpts.classString}`)}>
                        {children}
                        {pageStyleOpts && pageStyleOpts.bgImageEl}
                    </main>
                    <Footer {...footerMenu} hasMinimalNav={hasMinimalNav} />
                </div>
            </div>
        </TempPageProvider>
    );
};

export default Layout;
