/* Code taken from: https://www.gatsbyjs.com/docs/how-to/images-and-media/working-with-video/#writing-custom-components-for-hosted-video */
import React, { useRef } from "react";
import { useI18n } from "@v4/utils/i18nContext";
import { videoTranslations } from "../../translations/video";
import "./video.css";

const Video = ({ videoSrcURL, videoTitle, ...props }) => {
    // Set up localization context
    const { i18n } = useI18n();

    const cookiesMessage = useRef();
    // Figure out which provider it is. We can extend this as
    // we go along with various providers.
    const getVideoProvider = (url) => {
        if (!url) {
            return false;
        }

        // https://stackoverflow.com/a/67153064 <-- Vimeo URL formats covered
        const vimeo = /(?:http|https)?:?\/?\/?(?:www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;

        /** This regex has 2 capturing groups:
         *      1. The video ID
         *      2. The optional query string
         **/
        const youTube = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?\s]*)((?:\?)?\S*)/;
        /*
            Example urls:
            https://play.vidyard.com/iJafEwe96xSP41kgjMoXmY.html
            https://video.vidyard.com/watch/iJafEwe96xSP41kgjMoXmY?
        */
        const vidYard = /https?:\/\/(?:play\.|video\.)vidyard\.com\/(?:watch\/)?(\w+)/;

        const vimeoMatch = url.match(vimeo);

        // youtubeMatch will result in an array with size 3.
        // [0] = the entire match
        // [1] = the video ID
        // [2] = the optional query string (result in an emptry string if not present)
        const youTubeMatch = url.match(youTube);

        // vidyardMatch will result in an array with size 2.
        // [0] = the entire match
        // [1] = the video ID
        const vidYardMatch = url.match(vidYard);

        if (vimeoMatch) {
            return "https://player.vimeo.com/video/" + vimeoMatch[1];
        }

        if (youTubeMatch) {
            return "https://www.youtube.com/embed/" + youTubeMatch[1] + youTubeMatch[2];
        }

        if (vidYardMatch) {
            return "https://play.vidyard.com/" + vidYardMatch[1];
        }

        // Nothing matched so it might be something else that we don't like.
        return false;
    };

    const videoSrc = getVideoProvider(videoSrcURL);

    return (
        <div className={`${props.customClass ? props.customClass : "video"} relative`}>
            {videoSrc && (
                <>
                    <iframe
                        src={videoSrc}
                        title={videoTitle}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        className={"relative z-10"}
                        onLoad={() => {
                            cookiesMessage.current.classList.remove("show");
                        }}
                    />
                    <div className="videoCookiesMessage show" ref={cookiesMessage}>
                        {videoTranslations[i18n.curr.langCode].cookiesMessage}
                    </div>
                </>
            )}
        </div>
    );
};

export default Video;
