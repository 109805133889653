const getElementDistanceFromScreenEdge = (el, threshold) => {
    if (!el) return null;
    const rect = el.getBoundingClientRect();
    const windowWidth = window.innerWidth;

    return {
        left: rect.left - threshold,
        right: windowWidth - threshold - rect.right,
    };
};

export { getElementDistanceFromScreenEdge };
