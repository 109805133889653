import React from "react";
import camelCase from "lodash.camelcase";

const FooterSocials = ({ socials, cx }) => (
    <ul className={cx("socials")}>
        {socials?.entries?.map((entry, i) => {
            if (entry.icon) {
                return (
                    <li key={entry.id} className={cx(camelCase(entry.title))}>
                        <a href={entry.url}>
                            <img width="25" height="25" src={entry.icon[0]["original_secure_url"]} alt={entry.title} />
                        </a>
                    </li>
                );
            }
            return null;
        })}
    </ul>
);

export default FooterSocials;
