import React from "react";
import { graphql } from "gatsby";
import MenuGroup from "./MenuGroup";
import HeaderMenuItem from "./HeaderMenuItem";

export const fragment = graphql`
    fragment MainMenu on ContentfulMenu {
        name
        id
        entries {
            ... on ContentfulMenuItem {
                ...ItemFields
                subItems {
                    __typename
                    ... on ContentfulMenuGroup {
                        ...GroupFields
                        subItems {
                            ... on ContentfulMenuGroup {
                                ...GroupFields
                            }
                            ... on ContentfulMenuItem {
                                ...ItemFields
                            }
                        }
                    }
                    ... on ContentfulMenuItem {
                        ...ItemFields
                        subItems {
                            ... on ContentfulMenuGroup {
                                ...GroupFields
                            }
                            ... on ContentfulMenuItem {
                                ...ItemFields
                            }
                        }
                    }
                }
            }
        }
    }
`;

function Menu({ cx, ...props }) {
    const menuItems = props.data && props.data.entries;

    return (
        <nav className={cx("menu", "mainNav")} role="navigation">
            <ul className={cx("mainNavList")}>
                {menuItems &&
                    menuItems.map((entry, i) => {
                        entry.level = 1;
                        if (entry.__typename === "ContentfulMenuItem") {
                            return <HeaderMenuItem key={i} item={entry} loc={props.loc} cx={cx} />;
                        } else if (entry.__typename === "ContentfulMenuGroup") {
                            return <MenuGroup key={i} group={entry} loc={props.loc} cx={cx} />;
                        }
                        return null;
                    })}
            </ul>
        </nav>
    );
}

export default Menu;
