import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import axios from "axios";

export const fragment = graphql`
    fragment microPodcast on ContentfulMicroPodcast {
        id
        name
        podcastUrl
    }
`;

// The main component
const MicroPodcast = ({ podcastUrl }) => {
    const [status, setStatus] = useState("idle");
    const [data, setData] = useState([]);

    useEffect(() => {
        const endPoint = `https://api.simplecast.com/oembed?url=${podcastUrl}`;

        async function fetchData() {
            try {
                const result = await axios.get(endPoint);

                setData(result.data);
                setStatus("fetched");
            } catch (err) {
                /* eslint-disable no-console */
                console.log(err.message);
            }
        }
        fetchData();
    }, [podcastUrl]);

    return (
        <>
            {data && status === "fetched" && (
                <div
                    className={`mx-auto mb-4`}
                    style={{ maxWidth: `750px` }}
                    dangerouslySetInnerHTML={{ __html: data.html }}
                />
            )}
        </>
    );
};

export default MicroPodcast;
