import React from "react";
import { graphql } from "gatsby";
import Image from "../Image/Image";
import Link from "../Link";
import formatClassName from "@v4/utils/formatClassName";

export const fragment = graphql`
    fragment microBanner on ContentfulMicroBanner {
        id
        title
        image {
            # fluid (maxWidth: 248, transformations: "f_auto,q_auto")
            public_id
            width
            height
            format
        }
        imageAltText
        bgImage {
            # fluid (maxWidth: 1820, transformations: "f_auto,q_auto")
            public_id
            width
            height
            format
        }
        mainText
        buttonText
        pageLink {
            ...PageLink
        }
        url
        bgGradient
        variant
        formPromo
        formPromoConversionType
    }
`;

const MicroBanner = ({
    cx,
    item: { title, image, imageAltText, bgImage, bgGradient, mainText, buttonText, pageLink, url, variant },
}) => {
    // Set up image object for passing to our image handler
    const imageStyle =
        image && image[0]
            ? {
                  maxWidth: "124px",
                  width: "auto",
                  height: "auto",
              }
            : "";

    const to = url || (pageLink && pageLink.slug) || null;
    const bannerVariant = variant ?? "Light";

    return (
        <aside className={`${cx("microBanner")} ${formatClassName("bgGradient", bgGradient)}`}>
            <div className={cx("microBannerInner")}>
                {image && image[0] && (
                    <div className={cx("microBannerMedia")}>
                        <Image
                            className={cx("microBannerImage")}
                            image={image[0]}
                            style={imageStyle}
                            alt={imageAltText}
                        />
                    </div>
                )}
                {(title || mainText || buttonText) && (
                    <div className={cx("microBannerCopy", bannerVariant)}>
                        {title && <h3>{title}</h3>}
                        {mainText && <p>{mainText}</p>}
                        {buttonText && (pageLink || url) && (
                            <Link className={cx("microBannerButton", bannerVariant)} to={to}>
                                {buttonText}
                            </Link>
                        )}
                    </div>
                )}
            </div>
            {bgImage && bgImage[0] && (
                <Image
                    className={`${cx("microBannerBgImage", "bgWrapper", "backgroundImage")}`}
                    style={{ position: "absolute", zIndex: "1" }}
                    image={bgImage && bgImage[0]}
                />
            )}
        </aside>
    );
};

export default MicroBanner;
