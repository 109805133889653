import React from "react";
import formatContentfulRichText from "@v4/stitch/src/utils/formatContentfulRichText";
import classNames from "classnames/bind";
import BaseNotificationBar from "@v4/gatsby-theme-talend/src/components/modules/notificationBar/NotificationBar";
import * as notificationBarStyles from "./notificationBar.module.css";
const cx = classNames.bind(notificationBarStyles);

const NotificationBar = (props) => (
    <BaseNotificationBar
        formatContentfulRichText={formatContentfulRichText}
        cx={cx}
        {...props}
    />
);

export default NotificationBar;
