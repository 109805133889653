import React from "react";
import BaseFooter from "@v4/gatsby-theme-talend/src/components/footer/Footer";
import * as footerStyles from "./footer.module.css";
import classNames from "classnames/bind";
import { footerTranslations } from "../../translations/footer";

const cx = classNames.bind(footerStyles);

//  We need to pass the cx object (bound classes) all the wau down to the Footer components and every other component
//  referenced from within the Footer that also depends on classes in the footer.module.css

const Footer = (props) => <BaseFooter cx={cx} footerTranslations={footerTranslations} {...props} />

export default Footer;
