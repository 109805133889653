import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames/bind";
import * as imageStyles from "./Image.module.css";
const cx = classNames.bind(imageStyles);

/**
 * Image Component
 *
 * @Props
 * @param { image } Object: [Required] Image field information (object): { public_id, width, height, format }
 * @param { className } String: Class name to be applied to the wrapping <div>
 * @param { style } String: Style applied to the wrapping <div>
 * @param { imgStyle } String: Style to be applied to the <img> element
 * @param { alt } String: Alt text for the image
 * @param { isImgTag } String: Returns a simplified <img> tag without srcSet, sizes, etc.
 * @param { isInline } String: Determines whether the wrapping element is a <div> or <span>
 * @param { width } String: The image's maximum intended display width across all breakpoints
 * @param { height } String: The image's maximum intended display height across all breakpoints
 * @param { transforms } Array: Any additional transformations needed. Note: w_ and h_ values are scrubbed
 * @param { sizes } String: Used in conjunction with the srcSet attribute to determine image size output across breakpoints
 * @param { srcSet } Array: Custom srcSet if default isn't sufficient
 * @param { loadMethod = "lazy" } String: Loading method - defaults to "lazy", can be "eager" as well.
 * @param { format = "webp" } String: Image asset extension, defaults to "webp"
 *
 * Note: Most of the time, only the public_id is needed. Often times the width will be a calculation of the image's
 * intrinsic width.
 *
 *  Example image component:
 *  <Image
 *      image={props.image[0]}
 *      className={cx("exampleImageClassNameHere")}
 *      style={{ width: "100%", height: "100%" }}
 *      imgStyle={{ objectFit: "cover", objectPosition: "center" }}
 *      alt="Admiral Otter von Bizmarck"
 *      isImgTag={true | false}
 *      width={768}
 *      height={1086}
 *      transforms={["f_auto", "q_auto", "c_crop", "g_center"]}
 *      srcSet={[320, 640, 768, 1024, 1600]}
 *  />
 *
 * Most basic image component example:
 * <Image image={props.image[0]} />
 */

const Image = ({
    image,
    className = "",
    style,
    imgStyle,
    alt,
    isImgTag,
    isInline,
    width,
    height,
    transforms = ["q_auto"],
    sizes = "",
    srcSet,
    loadMethod = "lazy",
    format = "webp",
}) => {
    // States used for placeholder swap
    const [isLoaded, setIsLoaded] = useState(false);
    const [isCached, setIsCached] = useState(undefined);

    // Ref used for the image element
    const imageRef = useRef();

    // The isCached state is set according to if the image is loaded from memory or not (cached)
    useEffect(() => {
        imageRef?.current?.complete ? setIsCached(true) : setIsCached(false);
    }, [setIsCached, imageRef]);

    // Error control
    if (!image) return null;

    // Cloudinary base URL
    const srcBaseURL = `https://res.cloudinary.com/${process.env.GATSBY_CLOUDINARY_CLOUD_NAME}/image/upload/`;

    // Fallback dimensions if none passed. In most cases, CSS will override the HTML attributes.
    // This is intentionally placed outside of the SVG condition: they are not subject to density scaling.
    const imgWidth = width ?? image.width;
    const imgHeight = height ?? image.height;

    // Changes the wrapping element based on the isInline prop
    const WrappingEl = isInline ? "span" : "div";

    // When the image is an SVG, or `isImgTag` is true, use the HTML <img> tag instead of Gatsby-Image
    if (image.format === "svg" || isImgTag === true) {
        return (
            <WrappingEl style={style} className={cx("imageWrapper", "isImgTag", image.format, className)}>
                <img
                    className={`imageComponent isImgTag`}
                    src={srcBaseURL + image.public_id + "." + image.format}
                    width={imgWidth}
                    height={imgWidth}
                    style={imgStyle}
                    alt={alt}
                    loading={loadMethod}
                />
            </WrappingEl>
        );
    }

    // Derive an aspect ratio
    const aspectRatio = imgWidth / imgHeight;

    // Scrub transforms of w_ or h_ values
    let filteredTransforms = transforms.filter((transform) => !transform.includes("w_") && !transform.includes("h_"));

    // Add a trailing comma to transforms (if there are any) to chain with w_ and h_ in Cloudinary URL(s)
    const imageTransforms = filteredTransforms.length ? `${filteredTransforms.join()},` : "";

    // Placeholder height derived from aspect ratio and placeholder width of 240px
    let pHeight = Math.round(240 / aspectRatio);

    // Placeholder URL with blur effect
    const placeholderURL =
        srcBaseURL + imageTransforms + `w_240,h_${pHeight},q_auto:eco,e_blur:250/${image.public_id}.${format}`;

    // Empty array for populating with srcSet values
    let srcSetArr = [];

    // Default srcSet steps - can be overriden via props
    srcSet = srcSet ?? [
        320,
        480,
        640,
        690,
        720,
        768,
        800,
        960,
        1024,
        1120,
        1280,
        1440,
        1536,
        1600,
        1800,
        1920,
        2048,
        2240,
        2560,
        2880,
    ];

    // Build srcSet attributes based on sizes array
    if (srcSet[0] < imgWidth) {
        srcSet.forEach((size, i) => {
            // Derive height from aspect ratio
            const sizedHeight = Math.round(size / aspectRatio);

            // Build a Cloudinary URL w/the baseline transforms for the primary image
            let srcSetURL = `${srcBaseURL}${imageTransforms}w_${size},h_${sizedHeight}/${image.public_id}.${format}`;

            // Add a record for the "srcSet" attribute, if it's less than the image's intrinsic width
            if (size < imgWidth * 2) srcSetArr = [...srcSetArr, `${srcSetURL} ${size}w`];
        });
    }

    // Derive height from aspect ratio
    const intrinsicHeight = Math.round(imgWidth / aspectRatio);

    // Final srcSet value as the maximum display size of the image
    srcSetArr = [
        ...srcSetArr,
        `${srcBaseURL}${imageTransforms}w_${imgWidth},h_${intrinsicHeight}/${image.public_id}.${format} ${imgWidth}w`,
    ];

    // These classes are used to either show the image immediately when loaded from memory (cache), or to display a
    // placeholder until the image is fully loaded. It's initially unknown if the image is cached when the page is
    // server-side-rendered.

    // When cached: The main image is visible on initial load and the placeholder is invisible. When the HTML is loaded
    // it will be shown immediately. Soon after, javascript will verify that the image is indeed cached and change
    // nothing.

    // When un-cached: When the image is un-cached the browser will load the HTML, and the main image will be "visible"
    // but it won't be shown as it's still being downloaded. The placeholder will also be loading at this time. When
    // JavaScript has fired, it checks to see if the main image is loaded in a useEffect(). If the image hasn't yet
    // loaded, the `isCached` state is updated from `undefined` to `false`, which will make the placeholder visible
    // When the `onLoad` handler fires, the `isLoaded` is set to true which will animate the placeholder out, revealing
    // the main image.

    let isCachedClass = "";
    if (isCached === true) isCachedClass = "isCached";
    if (isCached === false) isCachedClass = "isNotCached";
    const isLoadedClass = isLoaded ? "isLoaded" : "";

    // Placeholder image component
    const placeholderImage = (
        <picture
            className={cx("placehodlerPictureEl", isLoadedClass, isCachedClass)}
            key={`placeholder-${image.public_id}`}
        >
            <img
                className={cx("imagePlaceholderComponent", isLoadedClass, isCachedClass)}
                src={placeholderURL}
                width={imgWidth}
                height={imgHeight}
                alt={alt || ""}
                loading={loadMethod}
                style={{ objectPosition: "center center", width: "100%", height: "100%", ...imgStyle }}
            />
        </picture>
    );

    // Full image component
    const fullImage = (
        <picture className={cx("imagePictureEl", isLoadedClass, isCachedClass)} key={image.public_id}>
            <img
                ref={imageRef}
                onLoad={() => {
                    setIsLoaded(true);
                }}
                className={cx("imageComponent", isLoadedClass, isCachedClass)}
                src={placeholderURL}
                alt={alt || ""}
                loading={loadMethod}
                width={imgWidth}
                height={imgHeight}
                srcSet={srcSetArr.join(", ")}
                sizes={sizes || `(max-width: ${imgWidth}px) 100vw, ${imgWidth}px`}
                style={imgStyle}
            />
        </picture>
    );

    return (
        <WrappingEl className={cx("imageWrapper", className)} style={style}>
            {placeholderImage}
            {fullImage}
        </WrappingEl>
    );
};

export default Image;
