import React from "react";
import MenuItem from "../MenuItem";
import isNoItem from "@v4/utils/isNoItem";

const FooterNav = ({ entries, cx }) => (
    <nav className={cx("nav")}>
        {entries &&
            entries.map((entry, i) => {
                return (
                    <ul key={entry.id} className={cx("menuGroup")}>
                        {entry.groupTitle && <span className={cx("groupTitle")}>{entry.groupTitle}</span>}
                        {entry.subItems &&
                            entry.subItems.map((entry, i) => {
                                if (isNoItem(entry.title)) return null;
                                return (
                                    <li key={entry.id} className={cx(entry.variant)}>
                                        <MenuItem item={entry} loc="footer" cx={cx} />
                                    </li>
                                );
                            })}
                    </ul>
                );
            })}
    </nav>
);

export default FooterNav;
