// Formats lists without the extra <p> tag
import React from "react";
import loadable from "@loadable/component";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import Link from "@v4/gatsby-theme-talend/src/components/Link";
import JumpDestination from "@v4/gatsby-theme-talend/src/components/JumpDestination/JumpDestination";
import MicroImage from "@v4/gatsby-theme-talend/src/components/MicroImage/MicroImage";
import MicroVideo from "@v4/gatsby-theme-talend/src/components/MicroVideo/MicroVideo";
import MicroPodcast from "@v4/gatsby-theme-talend/src/components/MicroPodcast/MicroPodcast";
import { getPrefixedPathByPageType } from "@v4/utils/pathPrefix";
import classNames from "classnames";

const MicroMarkdown = loadable(() => import("@v4/gatsby-theme-talend/src/components/MicroMarkdown/MicroMarkdown"));

// This function handles applying the customizations from our rich text editor UI extension
// @see contentful/ui-extensions/src/rich-text-extension.
// Basically, in the widget we are able to apply arbitrary "data" to any "block" of text
// and we're using that functionality to add custom widgets (text alignment, small text etc).
// Once we add the data attribute into the saved RTE data tree in the backend we can access it here
// to determine what customizations should be applied.
const applyCustomPlugins = (Tag = React.Fragment) => (node, children) => {
    let classes = [];

    if (node.data.textAlign === "center") classes.push("text-center");
    if (node.data.textAlign === "right") classes.push("text-right");
    if (node.data.textAlign === "left") classes.push("text-left");
    if (node.data.smallText) classes.push("text-small");
    if (node.data.class) {
        // Class array is needed because otherwise tailwind will purge our css.
        const styleClass = ["text-c_coral", "text-c_light_blue", "text-c_michael_gray"].find(
            (el) => el === node.data.class
        );

        classes.push(styleClass);
    }

    // If we need to apply classes to a node, it can't be a fragment.
    if (classes.length > 0 && Tag === React.Fragment) Tag = "span";
    // If the tag is a fragment it can't have classes.
    else if (Tag === React.Fragment) return children ? <Tag>{children}</Tag> : "";

    return children ? <Tag className={classNames(classes)}>{children}</Tag> : "";
};

// Convert newlines to break tags.
// @todo can we replace this with css `white-space: pre-wrap`?
const convertNewlineToBreak = (text) =>
    text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);

export default function formatContentfulRichText(MarketoForm, MicroBanner, MicroFormattedCode, CTA) {
    return {
        renderNode: {
            [BLOCKS.PARAGRAPH]: applyCustomPlugins("p"),
            [BLOCKS.HEADING_1]: applyCustomPlugins("h1"),
            [BLOCKS.HEADING_2]: applyCustomPlugins("h2"),
            [BLOCKS.HEADING_3]: applyCustomPlugins("h3"),
            [BLOCKS.HEADING_4]: applyCustomPlugins("h4"),
            [BLOCKS.HEADING_5]: applyCustomPlugins("h5"),
            [BLOCKS.HEADING_6]: applyCustomPlugins("h6"),
            [BLOCKS.LIST_ITEM]: (node) =>
                documentToReactComponents(node, {
                    renderNode: {
                        [BLOCKS.LIST_ITEM]: (node, children) => {
                            return <li className={node.data.checkList ? "checkmarkList" : null}>{children}</li>;
                        },
                        [BLOCKS.PARAGRAPH]: applyCustomPlugins(),
                        [INLINES.HYPERLINK]: (node, children) => <Link to={node.data.uri}>{children}</Link>,
                        [INLINES.ENTRY_HYPERLINK]: (node, children) => {
                            // Some page types have path prefixes, e.g. ContentfulBlog adds /blog/
                            const path = getPrefixedPathByPageType(
                                node.data.target?.slug,
                                node.data.target?.__typename
                            );

                            return <Link to={path}>{children}</Link>;
                        },
                        renderText: convertNewlineToBreak,
                    },
                }),
            [BLOCKS.EMBEDDED_ENTRY]: (node) => {
                if (node.data.target && node.data.target.__typename === "ContentfulMicroMarkdown") {
                    return <MicroMarkdown item={node.data.target} />;
                }
                if (node.data.target && node.data.target.__typename === "ContentfulMicroVideo") {
                    return <MicroVideo item={node.data.target} />;
                }
                if (node.data.target && node.data.target.__typename === "ContentfulMicroBanner") {
                    return <MicroBanner item={node.data.target} />;
                }
                if (node.data.target && node.data.target.__typename === "ContentfulMicroFormattedCode") {
                    return <MicroFormattedCode item={node.data.target} />;
                }
                if (node.data.target && node.data.target.__typename === "ContentfulMarketoForm") {
                    return <MarketoForm {...node.data.target} />;
                }
                if (node.data.target && node.data.target.__typename === "ContentfulMicroPodcast") {
                    return <MicroPodcast {...node.data.target} />;
                }
            },
            [INLINES.EMBEDDED_ENTRY]: (node) => {
                if (node.data.target && node.data.target.__typename === "ContentfulCta") {
                    return <CTA {...node.data.target} />;
                }
                if (node.data.target && node.data.target.__typename === "ContentfulJumpDestination") {
                    return <JumpDestination {...node.data.target} />;
                }
                if (node.data.target && node.data.target.__typename === "ContentfulMicroImage") {
                    return <MicroImage item={node.data.target} display={"inline"} />;
                }
            },
            [INLINES.HYPERLINK]: (node, children) => <Link to={node.data.uri}>{children}</Link>,
            [INLINES.ENTRY_HYPERLINK]: (node, children) => {
                // Some page types have path prefixes, e.g. ContentfulBlog adds /blog/
                const path = getPrefixedPathByPageType(node.data.target?.slug, node.data.target?.__typename);

                return <Link to={path}>{children}</Link>;
            },
        },
        renderText: convertNewlineToBreak,
    };
}
