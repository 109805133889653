import React from "react";
import { graphql } from "gatsby";
import FooterNav from "./FooterNav";
import FooterSub from "./FooterSub";
import FooterSocials from "./FooterSocial";
import { useI18n } from "@v4/utils/i18nContext";

export const fragment = graphql`
    fragment FooterNav on ContentfulMenu {
        name
        id
        entries {
            ... on ContentfulMenuGroup {
                name
                groupTitle
                variant
                __typename
                id
                subItems {
                    __typename
                    ... on ContentfulMenuItem {
                        title
                        description
                        variant
                        url
                        newWindow
                        pageLink {
                            ...PageLink
                        }
                        id
                    }
                }
            }
        }
    }
    fragment FooterSocials on ContentfulMenu {
        name
        id
        entries {
            ... on ContentfulMenuItem {
                title
                url
                id
                newWindow
                icon {
                    original_secure_url
                }
            }
        }
    }
    fragment FooterSubMenu on ContentfulMenu {
        name
        id
        entries {
            ... on ContentfulMenuItem {
                title
                url
                id
                newWindow
                pageLink {
                    ...PageLink
                }
            }
        }
    }
`;

// cx for CSS Modules

function Footer({ footerNav, footerSocials, footerSubMenu, hasMinimalNav, cx, footerTranslations }) {
    const entries = footerNav ? footerNav.entries : null;
    const { i18n } = useI18n();

    // The footer markup if hasMinimalNav is true
    const miniFooter = () => (
        <footer className={cx("siteFooter", "minimal")} role="contentinfo">
            <div className="container">
                {footerSubMenu && <FooterSub subMenu={footerSubMenu} />}
                <div className={cx("copyright")}>
                    &copy;{new Date().getFullYear()} {footerTranslations[i18n.curr.langCode].copyright}
                </div>
            </div>
        </footer>
    );

    // The default footer markup
    const defaultFooter = () => (
        <footer className={cx("siteFooter")} role="contentinfo">
            <div className={cx("container")}>
                {footerSocials && <FooterSocials socials={footerSocials} cx={cx} />}
                <FooterNav entries={entries} cx={cx} />
                {footerSubMenu && <FooterSub subMenu={footerSubMenu} />}
            </div>
            <div className={cx("copyright")}>
                &copy; 2005 - {new Date().getFullYear()} {footerTranslations[i18n.curr.langCode].copyright}
            </div>
        </footer>
    );

    // Decide which footer to show depending on the hasMinimalNav boolean
    return hasMinimalNav ? miniFooter() : defaultFooter();
}

export default Footer;
