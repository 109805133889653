import React from "react";
import { graphql } from "gatsby";
import Image from "../Image/Image";
import classNames from "classnames/bind";
import * as imageStyles from "./microImage.module.css";
import camelcase from "lodash.camelcase";

const cx = classNames.bind(imageStyles);

export const fragment = graphql`
    fragment microImage on ContentfulMicroImage {
        id
        imageAltText
        name
        image {
            public_id
            width
            height
            format
            secure_url
            raw_transformation
        }
        textWrap
    }
`;

const MicroImage = (props) => {
    const {
        display,
        item: { imageAltText, image, textWrap },
    } = props;

    // If a "display" property is passed and set to "inline", set a boolean
    const isInline = display === "inline";

    // If a wrapping class is set, camelcase for class use
    const textWrapClass = textWrap ? camelcase(textWrap) : "";

    // Error control
    if (!image || !image[0]) return null;

    // If not an SVG, cut the width by half via CSS.
    const displayWidth = image[0].format !== "svg" ? { width: `${image[0].width / 2}px`, maxWidth: "100%" } : {};

    const imgEl = (
        <>
            {image && image[0] && (
                <Image
                    className={cx("imageContainer")}
                    placeholderStyle={{ filter: `blur(10px)` }}
                    image={image[0]}
                    alt={imageAltText}
                    directURL={image[0].secure_url}
                    imgStyle={displayWidth}
                    isInline={isInline}
                />
            )}
        </>
    );

    // Wrap in a span if inline - this allows for positioning and wrapping within content
    if (isInline) return <span className={cx("microImage", "isInline", textWrapClass)}>{imgEl}</span>;

    return <div className={cx("microImage")}>{imgEl}</div>;
};

export default MicroImage;
