import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useIsomorphicLayoutEffect from "use-isomorphic-layout-effect";
import { graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";

export const fragment = graphql`
    fragment NotificationBar on ContentfulNotificationBar {
        updatedAt
        body {
            raw
            references {
                ...RTEReferences
            }
        }
        backgroundColor
        textShade
    }
`;

// Note:
// The LocalStorage value determines if the bar should show to the user at all
// The SessionStorage value determines of the bar has animated-in or not

const NotificationBar = ({ cx, formatContentfulRichText, ...props }) => {
    // Generate custom ID for Notification Bar based on when it was last updated
    const uniqueID = useMemo(() => {
        return `hide-nBar-${props.updatedAt}`;
    }, [props.updatedAt]);

    // Use Hook for getting and setting bar state
    const [showBar, setShowBar] = useState(true);

    // Create refs for use in visual interface changes
    const notificationBarRef = useRef();
    const notificationBarHeight = useRef(0);

    // Opening handler, animation on pageWrapper
    const handleNotificationBarOpen = useCallback(
        (pageWrapper) => {
            // When the showBar state var is set to true, we want to show the bar animate inward
            if (showBar && pageWrapper && notificationBarRef.current) {
                // Set the bar to be visible and relative to the pageWrapper
                notificationBarRef.current.style.position = "relative";
                notificationBarRef.current.style.opacity = "1";

                // If the key/value does not exist in sessionStorage, animate the bar
                if (window.sessionStorage.getItem(uniqueID) !== "true") {
                    // Cover the bar (immediate) by scooting pageWrapper upwards by bar's height
                    pageWrapper.style.transform = `translateY(-${notificationBarHeight.current}px)`;

                    setTimeout(() => {
                        // Change the pageWrapper's transition using the 'animateNotificationBar' class
                        pageWrapper.classList.contains("animateNotificationBar") === false &&
                            pageWrapper.classList.add("animateNotificationBar");

                        // Reset the pageWrapper's position, revealing the bar (animated via transition property)
                        pageWrapper.style.transform = null;

                        // Remove the animation class after the transition period is over
                        pageWrapper.ontransitionend = () => {
                            pageWrapper.classList.contains("animateNotificationBar") &&
                                pageWrapper.classList.remove("animateNotificationBar");
                        };
                    }, 300);
                }

                // Only animate this notification bar once per session.
                window.sessionStorage.setItem(uniqueID, "true");
            }
        },
        [showBar, notificationBarHeight, uniqueID]
    );

    // Closing handler, animation on pageWrapper
    const handleNotificationBarClose = useCallback(
        (pageWrapper) => {
            // Change the pageWrapper's transition using the 'animateNotificationBar' class
            pageWrapper.classList.add("animateNotificationBar");

            // Scoot the pageWrapper upward by the same offset as the notification bar's height
            pageWrapper.style.transform = `translateY(-${notificationBarRef.current.clientHeight}px)`;

            pageWrapper.ontransitionend = () => {
                // Reset notification bar's position, if bar is (still) present
                if (notificationBarRef.current) {
                    notificationBarRef.current.style.position = null;
                    notificationBarRef.current.style.opacity = null;
                }

                // Add the key/value into localStorage
                window.localStorage.setItem(uniqueID, "true");

                // Remove the animation class and reset pageWrapper transform
                pageWrapper.classList.remove("animateNotificationBar");
                pageWrapper.style.transform = null;

                // Disable/remove the bar
                setShowBar(false);
            };
        },
        [uniqueID]
    );

    // Measure notification bar height and determine bar presence before browser paint.
    useIsomorphicLayoutEffect(() => {
        notificationBarHeight.current = notificationBarRef?.current?.clientHeight;

        if (
            notificationBarHeight.current && // Bar exists
            window.localStorage.getItem(uniqueID) !== "true" && // Bar has NOT been closed by user
            window.sessionStorage.getItem(uniqueID) === "true" // Bar HAS been animated in
        ) {
            notificationBarRef.current.style.position = "relative";
            notificationBarRef.current.style.opacity = "1";
        }
    }, [props.pageWrapperRef]);

    useEffect(() => {
        // If the localStorage value is "true", do not show the bar
        if (window.localStorage.getItem(uniqueID) !== "true") handleNotificationBarOpen(props.pageWrapperRef.current);
    }, [handleNotificationBarOpen, props.pageWrapperRef, uniqueID]);

    // If the bar's body is empty or the bar's body begins with [[NoItem]], do not render the bar
    if (!props.body || renderRichText(props.body)[0]["props"]["children"][0] === "[[NoItem]]") return null;

    // Background color and text shade settings from Contentful
    const inlineStyles = props.backgroundColor ? { backgroundColor: `#${props.backgroundColor}` } : {};
    const textShade = props.textShade ? `textShade${props.textShade}` : "textShadeDark";

    // Clone the body and change the wrapping element type to fragment, effectively stripping any wrapping text elements
    let strippedBody = renderRichText(props.body, formatContentfulRichText());
    strippedBody = strippedBody.map((subEl) => ({ ...subEl, type: React.Fragment }));

    if (showBar === false) return null;

    return (
        <div
            className={"notificationBar " + cx("notificationBar", textShade)}
            style={inlineStyles}
            ref={notificationBarRef}
        >
            <div className="copy">{strippedBody}</div>
            <button
                className={cx("closeButton")}
                onClick={() => {
                    handleNotificationBarClose(props.pageWrapperRef.current);
                }}
            >
                {`\u00D7`}
            </button>
        </div>
    );
};

export default NotificationBar;
