import React, { useContext } from "react";
import { Link as GatsbyLink } from "gatsby";
import { useLocation } from "@reach/router";
import { useI18n } from "@v4/utils/i18nContext";
import { useStaticQuery, graphql } from "gatsby";
import { normalizePath } from "@v4/utils/normalizePathOrUrl";
import scrollWithHeaderOffset from "@v4/utils/scrollWithHeaderOffset";
import URI from "urijs";
import { TempPageContext } from "./TempPageProvider";

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
const Link = ({ children, to, activeClassName, className, onClick, newWindow, language, style }) => {
    // We need to find out on which domain we are, so we can properly set if a link is an external link or not
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        siteProductionDomain
                    }
                }
            }
        `
    );

    // Get current Location Object from the router
    const location = useLocation();
    const { i18n } = useI18n();

    // If this is the stitch site, check to see if the destination exists in stitch-www, otherwise use an anchor tag.
    // @todo remove this once we've switched entirely to stitch-www from stitch-gatsby.
    let isStitchLinkToOldSite = false;

    const pageList = useContext(TempPageContext);

    if (pageList?.length && !pageList.includes(normalizePath(to))) {
        isStitchLinkToOldSite = true;
    }

    // Is the link a jump link, or is it a link to the current page, in which case we can turn it into a standard anchor tag.
    const isJumpLink = to && to.startsWith("#");
    const isCurrentPage = to && to === location.pathname && !isJumpLink;
    // Check for a Menu Item entry with "[[COOKIE-CONSENT]]" in the URL field.
    const cookieConsentLink = to && to === "[[CookieConsentModal]]";

    if (to && cookieConsentLink) {
        return (
            <a href="#" className={className} style={style} onClick={() => window.updateConsent()}>
                {children}
            </a>
        );
    }

    // If there's no "to" then return a span with the child text.
    if (!to)
        return (
            <span className={className} style={style} onClick={onClick}>
                {children}
            </span>
        );

    // Create URI object from "to" for convenience (access to query string, fragment, etc).
    let uri = URI(to);

    // If the destination is not external (starts w/ http), a mail link (starts w/ mailto:), or an on page link (starts w/ #) and the language is not explicitly stated via the language
    // prop or an existing language prefix, prepend the current language using the i18n helper context.
    if (!to.startsWith("http") && !to.startsWith("mailto:") && !to.startsWith("#")) {
        if (!i18n.pathHasLangPrefix(to)) {
            to = language ? `${i18n.map[language].pathPrefix}${to}` : `${i18n.curr.pathPrefix}${to}`;
            // If language prefixes has been added recreate the URI object
            uri = URI(to);
            // Normalize the path component (potentially removing extra slashes) and set the "to" variable to "resource" (path + query + fragment).
            to = uri.pathname(normalizePath(uri.path())).resource();
        }
    }

    // If this link is internal to the current gatsby site, use GatsbyLink component.
    const shouldUseGatsbyLink =
        !["mailto", "http", "https", "ftp", "ftps"].includes(uri.protocol()) && !isStitchLinkToOldSite;

    // Use Gatsby Link for internal non-jump links, and standard anchor tag for everything else.
    if (!uri.fragment() && shouldUseGatsbyLink && !isCurrentPage) {
        return (
            <GatsbyLink to={to} activeClassName={activeClassName} className={className} style={style} onClick={onClick}>
                {children}
            </GatsbyLink>
        );
    }

    // Matches either the current domain or a production level domain.
    const matchDomain = RegExp(`^((f|ht)tps?:)?//(${location.host}|${site.siteMetadata.siteProductionDomain})`).test(
        to
    );

    // Link should honour the newWindow prop setting first, otherwise determine if it should open an a new window using the matchDomain regex and isCurrentPage, and isStitchLinkToOldSite.
    const shouldOpenNewWindow = newWindow ?? (!matchDomain && !isCurrentPage && !isStitchLinkToOldSite);

    // Get attributes ready to spread if link should open in a new window.
    const newWindowAttributes = shouldOpenNewWindow ? { rel: "noopener noreferrer", target: "_blank" } : {};

    // If link is a same-page jump-link, scroll to the element.
    let jumpLinkClickHandler;
    if (isJumpLink && uri.fragment()) {
        jumpLinkClickHandler = (e) => {
            e.preventDefault();

            // Add browser state for in page jump-links so that users can navigate between them w/
            // forward/back buttons.
            window.history.pushState(
                window.history.state,
                document.title,
                URI(location.href)
                    .hash(to)
                    .resource()
            );
            scrollWithHeaderOffset(to);

            if (onClick) onClick();
        };
    }

    return (
        <a
            href={to}
            className={className}
            style={style}
            onClick={jumpLinkClickHandler ?? onClick}
            {...newWindowAttributes}
        >
            {children}
        </a>
    );
};

export default Link;
