import React from "react";
import { graphql } from "gatsby";
import classNames from "classnames/bind";
import * as MicroVideoStyles from "./microVideo.module.css";
import Video from "../Video/Video";

const cx = classNames.bind(MicroVideoStyles);

export const fragment = graphql`
    fragment microVideo on ContentfulMicroVideo {
        id
        name
        videoTitle
        video
        videoAlignment
    }
`;

// The main component
const MicroVideo = (props) => {
    const alignment = props.item.videoAlignment ?? "Center";
    return (
        <div className={cx("videoContainer", alignment)}>
            <Video videoSrcURL={props.item.video} videoTitle={props.item.videoTitle} customClass={cx("video")} />
        </div>
    );
};

export default MicroVideo;
