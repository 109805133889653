export const videoTranslations = {
    en: {
        cookiesMessage: "Please enable cookies to access this video content.",
    },
    "en-GB": {
        cookiesMessage: "Please enable cookies to access this video content.",
    },
    fr: {
        cookiesMessage: "Veuillez activer les cookies pour accéder à cette vidéo.",
    },
    de: {
        cookiesMessage: "Bitte aktivieren Sie Cookies, um auf diesen Videoinhalt zuzugreifen.",
    },
    it: {
        cookiesMessage: "Si prega di abilitare i cookie per accedere a questo contenuto video.",
    },
    ja: {
        cookiesMessage: "この動画コンテンツにアクセスできるよう、Cookieを有効にしてください。",
    },
};
