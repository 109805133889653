import React from "react";
import BaseCta from "@v4/gatsby-theme-talend/src/components/Cta/Cta";
import classNames from "classnames/bind";
import * as ctaStyles from "./cta.module.css";

const cx = classNames.bind(ctaStyles);

const CTA = (props) => <BaseCta cx={cx} {...props} />;

export default CTA;
